<template>
  <section class="about-us">
    <section class="call-to-action">
      <h1 class="call-to-action__title">O Nas</h1>
      <p class="call-to-action__paragraph">
        Profesjonalna kadra, wyskiej jakości sprzęt, dobra atmosfera, duży box
        specjalnie do trenowania crossfitu
      </p>
    </section>
    <section class="introduction">
      <base-slider classes="introduction__slider"></base-slider>
      <p class="introduction__text">
        Jesteśmy afiliowanym boxem o powierzchni 400m<sup>2</sup> i
        funkcjonujemy już od ponad 4 lat. Nasza kadra to trenerzy, zawodnicy
        oraz specjaliści, którzy regularnie się szkolą i biorą udział w licznych
        seminariach w kraju oraz poza nim. Trenują u nas zarówno osoby, które
        chcą poprawić swoje zdrowie i sprawność fizyczną, jak i osoby, które juz
        coś wcześniej trenowały i chcą spróbować czegoś nowego.
        <br />
        <br />
        Posiadamy różnego rodzaju zajęcia, tak abyś Ty mógł znaleźć coś dla
        siebie.
        <br />
        <br />
        Chcesz dowiedzieć się o nich więcej?
        <br />
        <text-links href="/subpages/aboutUs.html#our-classes"
          >Zerknij tutaj</text-links
        >
      </p>
    </section>
    <section class="our-localization">
      <h2 class="our-localization__title">Nasza Lokalizacja</h2>
      <iframe
        class="our-localization__map"
        src="https://maps.google.com/maps?q=CrossFit%20Black%20Star&t=&z=15&ie=UTF8&iwloc=&output=embed"
        frameborder="0"
        scrolling="no"
      ></iframe>
    </section>
    <section id="our-classes" class="our-classes">
      <h2 class="our-classes__title">Nasze Zajęcia</h2>
      <div v-for="{ title, desc, id } in ourClasses" :key="id">
        <h3 class="our-classes__classes-title">{{ title }}</h3>
        <p v-html="desc" />
      </div>
    </section>
  </section>
</template>

<script>
import BaseSlider from "../components/main-components/slider/Slider.vue";

export default {
  components: {
    BaseSlider,
  },
  data() {
    return {
      ourClasses: this.$store.state.ourClasses,
    };
  },
  beforeRouteLeave(_, __, next) {
    if (this.$store.getters.isPhoneMenuOpen) {
      this.$store.commit("openClosePhoneMenu");
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
@import "./scss/about-us.scss";
</style>
